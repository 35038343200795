import { useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { isCorrectRulesData } from '../../modules/isCorrectRulesData'
import { parseWithLinks } from '../../modules/htmlParser'
import { CSSTransition } from 'react-transition-group'
import md5 from 'md5'
import './style.scss'

type Props = {
  content: string
}

export default function Rules(props: Props) {
  const divEl = useRef<HTMLDivElement>(null)
  const cookiesExpiresDate: Date = new Date(new Date().setMonth(new Date().getMonth() + 2))
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [cookies, setCookie] = useCookies(['pod_rules'])
  const [showRules, setShowRules] = useState<boolean>(!isCorrectRulesData(cookies, props.content))

  const handleOnChange = () => {setIsChecked(!isChecked)}
  const setRules = () => {
    setCookie('pod_rules', md5(props.content), {path: '/', expires: cookiesExpiresDate })
    setShowRules(false)
  }

  return (<CSSTransition timeout={3000} classNames="fade" in={showRules} nodeRef={divEl} unmountOnExit>
    <div className="Rules" ref={divEl}>
      <div className="Rules-overlay">
        <div className="Rules-window">
          <div className="Rules-wrapper">
            {parseWithLinks(props.content)}
            <div className="wrapper">
              <input
                type="checkbox"
                id="rules"
                checked={isChecked}
                onChange={handleOnChange}
              />
              <label htmlFor="rules">Please tick to confirm that you have read and understood the statements above before closing this window</label>
            </div>
            <button className="button button-primary" disabled={!isChecked} onClick={setRules}>Accept and Close</button>
          </div>
        </div>
      </div>
    </div>
  </CSSTransition>
  )
}