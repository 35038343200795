import './style.scss';
import cn from 'classnames';

type Props = {
  className: string,
  data: {
    id: string,
    title: string,
    color: string,
    image: string,
  },
  children?: string | JSX.Element | JSX.Element[]
}

export default function TrialGroup(props: Props) {
  const iconStyles = {
    backgroundColor: props.data.color
  }

  return (
    <div className={cn('TrialGroup', props.className)}>
      <div className="TrialGroup-icon" style={iconStyles}>
        <img src={props.data.image} alt="Icon"/>
      </div>
      <div className="TrialGroup-content">
        <h2 className="TrialGroup-title">{props.data.title}</h2>
        <div className="TrialGroup-trials">
          {props.children}
        </div>
      </div>
    </div>
  );
}
