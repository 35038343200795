import { Geo, loadGeos } from '../../../modules/api'
import { isAuthenticated } from '../../../modules/auth'
import { FormEventHandler, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { recordEvent } from '../../../modules/stats'
import { useCookies } from 'react-cookie'
import { isUndefined } from 'lodash-es'
import '../../auth/style.scss'

type Props = {
  method: string,
  password: string,
  onSubmit: (isAuth: boolean) => void
}

export const AuthGeo = (props: Props) => {
  const cookiesExpiresDate: Date = new Date(new Date().setMonth(new Date().getMonth() + 2))
  const [cookies, setCookie] = useCookies(['pod_geo'])
  const urlParams = new URLSearchParams(window.location.search)
  const defaultGeo = urlParams.get('geo')
  defaultGeo && setCookie('pod_geo', defaultGeo, {path: '/', expires: cookiesExpiresDate})

  const hcpFormEl = useRef(null)
  const geoFormEl = useRef(null)
  const redirectMsgEl = useRef(null)

  const [isAuth, setIsAuth] = useState<boolean>(isAuthenticated(cookies, props.method, props.password))
  const [isHcp, setIsHcp] = useState<boolean>()
  const [geo, setGeo] = useState<string>()
  const [geos, setGeos] = useState<Geo[]>()
  const [hcpPassed, setHcpPassed] = useState<boolean>()

  const hcpOnSubmit:FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    setHcpPassed(isHcp)
  }

  useEffect(() => {!isAuth && loadGeos(setGeos)}, [isAuth])

  const geoOnSubmit:FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (geo) {
      setCookie('pod_geo', geo, {path: '/', expires: cookiesExpiresDate})
      setIsAuth(true)
      props.onSubmit(true)
      recordEvent('Self-ID', geo)
    }
  }

  hcpPassed === false && setTimeout(() => window.location.href = 'https://www.pfizer.com', 4000)

  return (
    <div className="Auth-window">
      <CSSTransition in={isUndefined(hcpPassed)} timeout={3000} classNames="fade" unmountOnExit nodeRef={hcpFormEl}>
        <form className="Auth-form-hcp" onSubmit={hcpOnSubmit} ref={hcpFormEl}>
          <h2>Please verify that you are <br/>a Healthcare Professional</h2>
          <p>This information is intended for Healthcare Professionals</p>
          <div className="radio">
            <input type="radio" id="is_hcp_yes" checked={isHcp === true} onChange={()=>setIsHcp(true)}/>
            <label htmlFor="is_hcp_yes">I am a Healthcare/Medical Professional</label>
          </div>
          <div className="radio">
            <input type="radio" id="is_hcp_no" checked={isHcp === false} onChange={()=>setIsHcp(false)} />
            <label htmlFor="is_hcp_no">I am NOT a Healthcare/Medical Professional</label>
          </div>
          <input className="button button-primary" type="submit" value="Continue" disabled={isHcp === undefined} />
        </form>
      </CSSTransition>

      <CSSTransition in={hcpPassed} timeout={3000} classNames="fade" unmountOnExit nodeRef={geoFormEl}>
        <form className="Auth-form-geo" onSubmit={geoOnSubmit} ref={geoFormEl}>
          <h2>Please select your location</h2>
          {geos === undefined 
            ? <div>Loading geo regions...</div>
            : <div>
                <div className="Auth-choices">
                  {geos.map(g => 
                    <div className="radio" key={g.id}>
                      <input type="radio" id={`geo_${g.id}`} checked={geo === g.name} onChange={()=>setGeo(g.name)} />
                      <label htmlFor={`geo_${g.id}`}>{g.name}</label>
                    </div>
                    )}
                </div>
                <input type="submit" className="button button-primary" value="Continue" disabled={geo === undefined}/>
              </div>
          }
        </form>
      </CSSTransition>

      <CSSTransition in={hcpPassed === false} timeout={300} classNames="fade" unmountOnExit nodeRef={redirectMsgEl}>
        <div className="Auth-redirect-message" ref={redirectMsgEl}>
          <h2>This website is intended for use by Healthcare/Medical Professionals</h2>
          <p>You will now be re-directed to <a href="https://www.pfizer.com">www.pfizer.com</a></p>
        </div>
      </CSSTransition>
    </div>
  )
}