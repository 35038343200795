import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { recordEvent } from './stats';

export function parseWithLinks(text: string) {
  const options: HTMLReactParserOptions = {
    // @ts-ignore
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href) {
        if (!attribs.href.startsWith('http')) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
        else {
          attribs.onClick = () => { recordEvent('External links', attribs.href) };
        }
      }
    }
  };
  
  return parse(text, options);
}

export function parseWithoutParagraphs(text: string) {
  const options: HTMLReactParserOptions = {
    // @ts-ignore
    replace: ({name, children}) => { if (name === 'p') return <>{domToReact(children)}</> }
  };
  
  return parse(text, options);
}