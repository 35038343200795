import { useState } from 'react';
import './style.scss';

type Props = {
  children: string | JSX.Element | JSX.Element[],
  onClose: () => void
}

export default function Announcement(props: Props) {
  const [hideAnnouncement, setHideAnnouncement] = useState<string|null>(window.localStorage.getItem('pod_hide_announcement'));
  hideAnnouncement && window.localStorage.setItem('pod_hide_announcement', 'yes');

  const onClose = () => {
    setHideAnnouncement('yes');
    props.onClose();
  }

  return (
    <>
      {isAnnouncementShown() && <div className="Announcement">
        <div className="text">{props.children}</div>
        <button className="close" onClick={onClose}/>
      </div>}
    </>
  );
}

export function isAnnouncementShown() {
  return window.localStorage.getItem('pod_hide_announcement') !== 'yes';
}