import './style.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Logo from '../../assets/images/logo.svg';

import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import forceDownload from '../../modules/forceDownload';
import { recordEvent } from '../../modules/stats';
import { parseWithLinks } from '../../modules/htmlParser';

type Props = {
  url: string,
  title?: string,
  contentType: string,
  disclaimer?: string,
  onClose: () => void,
  extra?: string | JSX.Element | JSX.Element[]
}

export default function DocViewer(props: Props) {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(props.disclaimer === undefined || props.disclaimer.trim() === '');

  const [zoom, setZoom] = useState(1);

  const zoomStep = 0.2;
  const zoomIn = () => { zoom < 2.4 && setZoom(zoom + zoomStep)}
  const zoomOut = () => { zoom > 0.21 && setZoom(zoom - zoomStep)}

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: {numPages: number}) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const forceTitle = props.title || 'Undefined';
    recordEvent('Content views', forceTitle, props.contentType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="DocViewer">
      {props.disclaimer !== undefined && !disclaimerAccepted && 
        <div className="disclaimer">
          <div>
            {parseWithLinks(props.disclaimer)}
            <button className="button button-primary" onClick={()=>setDisclaimerAccepted(true)}>Yes</button>
            <button className="button button-primary" onClick={()=>props.onClose()}>No</button>
          </div>
        </div>
      }
      <div className="DocViewer-header">
        <img className="DocViewer-logo" src={Logo} alt="Pfizer Oncology" />
        {props.title && <h2 className="DocViewer-title"><span>{props.title}</span></h2>}
        <div className="DocViewer-toolbar">
          <button className="zoom-out" onClick={()=>zoomOut()}/>
          <button className="zoom-in" onClick={()=>zoomIn()}/>
          <button className="prev-page" onClick={() => pageNumber > 1 && setPageNumber(pageNumber-1)}/>
          <span className="page-indicator">{pageNumber} / {numPages}</span>
          <button className="next-page" onClick={() => pageNumber < numPages && setPageNumber(pageNumber+1)}/>
          <button className="download" onClick={() => download(props.url, props.title)}/>
        </div>
        {props.extra && <div className="DocViewer-extra">{props.extra}</div>}
        <button className="DocViewer-close" onClick={() => props.onClose()} />
      </div>
      <Document
        className="DocViewer-document"
        file={props.url}
        onLoadSuccess={onDocumentLoadSuccess}
        onItemClick={({pageNumber})=>setPageNumber(parseInt(pageNumber))}
        externalLinkTarget="_blank"
      >
        <Page className="DocViewer-page" pageNumber={pageNumber} scale={zoom}/>
      </Document>
    </div>
  );
}

function download(url: string, title?: string) {
  const forceTitle = title || 'Undefined';
  recordEvent('File downloads', forceTitle, url);
  forceDownload(url);
}