import './style.scss';

import parse from 'html-react-parser';
import { ResourceCategory, Document, Video } from '../../modules/api';
import Header from '../header';
import TrialGroup from '../trialGroup';
import { Link } from 'react-router-dom';

type Props = {
  categories: ResourceCategory[],
  resources: (Document | Video)[]
}

export default function Resources(props: Props) {
  const nonEmptyCategories = props.categories?.filter(
    c => props.resources.filter(r => r.categoryId === c.id).length > 0
  );

  return (
    <div className="Resources">
      <div className="overlay"/>
      <div className="page-content">
        <Header back="/menu">Emerging Approaches In Oncology</Header>
        <div className="list">
          <div className="columns">
            {nonEmptyCategories?.map(category => 
              <TrialGroup className="group" key={category.id} data={category}>
                {props.resources.filter(r => r.categoryId === category.id).map(
                  r => <Link key={r.id} to={r.path} className="Resource">{parse(r.title)}</Link>
                )}
              </TrialGroup>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
