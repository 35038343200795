import './style.scss';

type Props = {
  content?: string | JSX.Element | JSX.Element[]
}

export default function Home(props: Props) {
  return (
    <div className='Home'>
      <div className="page-content">{props.content}</div>
    </div>
  )
}