import "./style.scss";
import Logo from '../../assets/images/logo.svg';

export default function Preloader() {
  return <div className="Preloader">
    <div>
      <img src={Logo} alt="Pfizer Oncology" />
      <div>Loading...</div>
    </div>
  </div>
}