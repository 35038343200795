import './style.scss';
import Logo from '../../assets/images/logo.svg';
import cn from 'classnames';
import { ReactNode, useState } from 'react';

type Props = {
  className: string,
  children: ReactNode,
  isOpen?: boolean,
  header?: ReactNode,
  withLogo?: boolean,
  closeInWindow?: boolean,
  onClose?: () => void; 
}

export default function Modal(props: Props) {
  const [isOpen, setIsOpen] = useState<Boolean>(Boolean(props.isOpen));

  function onCloseClick() {
    setIsOpen(false);
    props.onClose && props.onClose();
  }

  return (
    <div className={cn('Modal', props.className, {'is-open': isOpen})}>
      <div className="Modal-overlay">
        {props.withLogo && <img src={Logo} alt="Pfizer Oncology" className="Modal-logo" />}
        {props.header && <h2 className="Modal-header">{props.header}</h2>}
        {!props.closeInWindow && <button className="Modal-close" onClick={onCloseClick} />}
        <div className="Modal-window">
          {props.closeInWindow && <button className="Modal-close" onClick={onCloseClick} />}
          {props.children}
        </div>
      </div>
    </div>
  );
}