import { CSSTransition } from 'react-transition-group'
import { AuthGeo } from './authGeo'
import { AuthPassword } from './authPassword'
import { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useContext } from 'react';
import VersionContext from '../../contexts/versionContext';
import SessionContext from '../../contexts/sessionContext'

export default function Auth() {
  const version = useContext(VersionContext);
  const session = useContext(SessionContext);

  const authWrapperEl = useRef<HTMLDivElement>(null)
  const [isHidden, setIshidden] = useState<boolean>(session.isAuthenticated || session.authMethod === undefined)

  useEffect(() => {
    setIshidden(session.isAuthenticated || session.authMethod === undefined);
  }, [session])

  return (<CSSTransition timeout={0} classNames="fade" in={!isHidden} nodeRef={authWrapperEl} unmountOnExit>
    <div className="Auth" ref={authWrapperEl}>
      <div className="Auth-overlay">
        {version?.authMethod === 'hcp_geo' && <AuthGeo method={version.authMethod} password={version.password} onSubmit={isAuth => setIshidden(isAuth)} />}
        {version?.authMethod === 'password' && <AuthPassword onSubmit={isAuth => setIshidden(isAuth)} />}
      </div>
    </div>
  </CSSTransition>
  )
}