import './style.scss'

import { Tile as TileData } from '../../modules/api';
import Tile from '../tile';
import { parseWithLinks } from '../../modules/htmlParser';
import Header from '../header';

type Props = {
  tiles: TileData[]
}

export default function Menu(props: Props) {
  return (
    <div className='Menu'>
        <div className="overlay" />
        <div className="Menu-grid page-content">
        <Header back="/menu">Pfizer Oncology Development</Header>
          {props.tiles.map(t => 
            <Tile className={t.cssClass} key={t.id} {...t}>
              {parseWithLinks(t.body)}
            </Tile>
          )}
        </div>
    </div>
  );
}