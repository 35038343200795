import './grid-box-menu.scss';
import { Compound, GridBox } from "../../modules/api";
import React, { useEffect, useState } from 'react';

type Props = {
  gridBox: GridBox,
  onCompoundClick: (compound: Compound) => void,
  onClose: () => void,
  initialStyles: React.CSSProperties,
}

export default function GridBoxMenu(props: Props) {
  let styles: React.CSSProperties = { ...props.initialStyles };
  styles.backgroundColor = props.gridBox.color;

  // Allow animated appearance.
  const [cssClass, setCssClass] = useState<string>('');
  useEffect(() => { setCssClass('active'); }, []);

  return (
    <div className={`GridBoxMenu ${cssClass}`} style={styles}>
      <h2>View Slides</h2>
      <ul>
        {props.gridBox.compounds && props.gridBox.compounds.map(c => (
          <li 
            key={c.id} 
            className="GridBoxMenuItem" 
            onClick={() => props.onCompoundClick(c)}
          >
            <div className="title">{c.title}</div>
            <div className="description">({c.description})</div>
          </li>
        ))}
      </ul>
      <button className="close" onClick={props.onClose} />
    </div>
  );
}