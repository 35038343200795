import './style.scss';

import { useHistory, useParams } from 'react-router-dom';
import Header from '../header';
import Matrix from './matrix';
import DocViewer from '../docViewer';
import { CancerType, Compound, GridBox, GridGroup, MolecularTarget, Phase } from '../../modules/api';
import ProgramNav from './nav';
import GridBoxMenu from './grid-box-menu';
import { History } from 'history';
import { parseWithLinks } from '../../modules/htmlParser';
import Overlay from '../overlay';
import { useContext } from 'react';
import VersionContext from '../../contexts/versionContext';
import SessionContext from '../../contexts/sessionContext';

type Props = {
  cancerTypes: CancerType[],
  molecularTargets: MolecularTarget[],
  phases: Phase[],
  compounds: Compound[],
  gridBoxes: GridBox[],
  defaultMatrix: string,
  title: string,
  footer: string
}

export default function Program(props: Props) {
  const version = useContext(VersionContext);
  const session = useContext(SessionContext);

  const params = useParams<{matrix: string, box?: string, compound?: string}>();
  const history = useHistory();

  params.matrix || history.replace('/program/' + props.defaultMatrix);

  const currentGridBox = props.gridBoxes.find(c => c.id === params.box);
  const currentCompound = props.compounds.find(c => c.id === params.compound);

  const gridGroups:{[key: string]: GridGroup[]} = {
    cancer: props.cancerTypes,
    target: props.molecularTargets,
    phase: props.phases
  }

  const currentGridBoxEl = params.box ? document.getElementById(`grid-box-${params.box}`) : undefined;
  const gridBoxMenuStyles: React.CSSProperties = {}
  if (currentGridBoxEl) {
    gridBoxMenuStyles.left = `calc(var(--nav-width) + ${currentGridBoxEl.offsetLeft}px)`
    gridBoxMenuStyles.top = `calc(var(--header-height) + var(--announcement-height) + 2rem + ${currentGridBoxEl.offsetTop}px)`
    gridBoxMenuStyles.width = `${currentGridBoxEl.offsetWidth}px`
    gridBoxMenuStyles.height = `${currentGridBoxEl.offsetHeight}px`
  }

  return <div className="Program">
    <div className="overlay" />
    <div className="page-content">
      <Header back='/menu'>{props.title}</Header>
      <ProgramNav />
    
      <div className="Program-content">
        {params.matrix && gridGroups[params.matrix] && <Matrix
          key={params.matrix}
          slug={params.matrix}
          gridGroups={gridGroups[params.matrix]}
          gridBoxes={props.gridBoxes.filter(gb => gb.matrix === params.matrix)}
          onGridBoxClick={(gridBox) => onGridBoxClick(gridBox, params.matrix, history)}
        />}

        {props.footer && <div className="Program-footer">{parseWithLinks(props.footer)}</div>}
      </div>
      
      {currentCompound && 
        <DocViewer 
          url={currentCompound.document} 
          title={currentCompound.title} 
          onClose={()=>goBack()}
          disclaimer={(session.isHcp === true) ? version?.compoundDisclaimer : ''}
          contentType="Compound"
        />
      }

      {currentGridBox && <>
        <Overlay />
        <GridBoxMenu 
          gridBox={currentGridBox} 
          onCompoundClick={(compound) => onCompoundClick(
            compound, 
            currentGridBox,
            params.matrix,
            history
          )}
          onClose={() => onGridBoxMenuClose(params.matrix, history)}
          initialStyles={gridBoxMenuStyles}
        />
      </>}
    </div>
  </div>
}

const onGridBoxClick = (gridBox: GridBox, matrix: string, history: History) => {
  if (gridBox.compoundIds?.length) {
    let path = `/program/${matrix}/${gridBox.id}`;
    if (gridBox.compoundIds.length === 1) {
      path += '/' + gridBox.compoundIds[0]
    }
    history.push(path);
  }
}

const onCompoundClick = (compound: Compound, gridBox: GridBox, matrix: string, history: History) => {
  history.push(`/program/${matrix}/${gridBox.id}/${compound.id}`);
}

const onGridBoxMenuClose = ( matrix: string, history: History) => {
  history.push(`/program/${matrix}`);
}

const goBack = function () {
  if (window.history.length > 2) {
    window.history.back();
  }
  else {
    window.location.pathname = ''
  }
}