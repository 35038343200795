import React from 'react';

export type Session = {
  isAuthenticated: boolean,
  authMethod?: string,
  isHcp?: boolean // set "true" if HCP password is used
}

const SessionContext = React.createContext<Session>({ isAuthenticated: false });

export const SessionContextProvider = SessionContext.Provider;
export default SessionContext;
