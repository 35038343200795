import './matrix.scss';

import { GridGroup as GridGroupData, GridBox as gridBoxData } from '../../modules/api';
import GridBox from './grid-box';
import CompoundGridBox from './compound-grid-box';
import GridGroup from './grid-group';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  slug: string,
  gridGroups: GridGroupData[],
  gridBoxes: gridBoxData[],
  onGridBoxClick: (gridBox: gridBoxData) => void
}

export default function ProgramMatrix(props: Props) {
  const [expandedGroup, setExpeandedGroup] = useState<string>();
  const isSmScreen = useMediaQuery({ maxWidth: '767px' });

  return <div className={`ProgramMatrix matrix--${props.slug}`}>
    {props.gridGroups.filter(g => g.gridAreaL || g.gridAreaM).map(g => <React.Fragment key={g.id}>
      <GridGroup key={g.id} {...g} onClick={() => setExpeandedGroup(expandedGroup === g.id ? undefined : g.id)} />
      {(props.gridBoxes.filter(gb => 
        [gb.cancerTypeId, ...gb.phaseIds, ...gb.molecularTargetIds].includes(g.id) 
        && (g.id === expandedGroup || !isSmScreen)
      ).map(gb => 
        <CompoundGridBox 
          key={gb.id}
          id={`grid-box-${gb.id}`}
          gridAreaL={gb.gridAreaL}
          gridAreaM={gb.gridAreaM}
          color={gb.color}
          data={gb}
          onClick={() => props.onGridBoxClick(gb)}
        />
      ))}
    </React.Fragment>)}

    {props.gridGroups.filter(g => g.image).map(g => <React.Fragment key={g.id}>
      {(g.imageGridAreaL || g.imageGridAreaM) && <GridBox {...g}
        gridAreaL={g.imageGridAreaL}
        gridAreaM={g.imageGridAreaM}
        key={`grid-image-${g.id}`} 
        id={`grid-image-${g.slug}`} 
        className="grid-image"
        style={{backgroundImage: `url(${g.image})`}}
      />}
    </React.Fragment>)}
  </div>
}