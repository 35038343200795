import './style.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Trial as TrialData } from '../../modules/api';
import { parseWithoutParagraphs } from '../../modules/htmlParser';

type Props = {
  className: string,
  data: TrialData
}

export default function Trial(props: Props) {
  const text = parseWithoutParagraphs(props.data.description);

  return ( 
    <div className={cn('Trial', props.className)}>
      { props.data.document 
        ? <Link to={`/trials/${props.data.id}`}>{text}</Link>
        : <span>{text}</span>
      }
    </div>
  );
}