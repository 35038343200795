import './style.scss';
import cn from 'classnames';
import { recordEvent } from '../../modules/stats';
import { useEffect } from 'react';

type Props = {
  title: string,
  className?: string,
  children: string | JSX.Element | JSX.Element[],
  onClose: () => void
}

export default function StaticContent(props: Props) {
  useEffect(() => {
    recordEvent('Content views', props.title, 'Static content');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={cn('StaticContent', props.className)}>
    <div className="overlay" />
    <div className="page-content">
      <div className="window">
        <button className="close" onClick={()=>props.onClose()}/>
        {props.children}
      </div>
    </div>
  </div>
}