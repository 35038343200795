import { parseWithLinks } from '../../modules/htmlParser'
import './style.scss'

type Props = {
  content: string
  onAccept: () => void
  onDismiss: () => void
}

export default function ExternalLinkWarning(props: Props) {
  return <div className="ExternalLinkWarning">
    <div className="ExternalLinkWarning-overlay">
      <div className="ExternalLinkWarning-window">
        {parseWithLinks(props.content)}
        <div className="btn-wrapper">
          <button className="button button-primary button-outline" onClick={()=>props.onDismiss()}>Go back</button>
          <button className="button button-primary with-arrow" onClick={()=>props.onAccept()}>Continue</button>
        </div>
      </div>
    </div>
  </div>
}