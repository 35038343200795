/**
 * Get configuration variable.
 * @param variableName Variable name to get.
 */
export function getConfig(variableName: string, defaultValue?: string): string | undefined {
  let value = process.env[variableName];
  value === undefined && (value = defaultValue)
  value !== undefined || console.warn(`${variableName} is not configured`);
  return value;
}
