import './style.scss';

import { Trial as TrialData, CancerType } from '../../modules/api';
import Header from '../header';
import Modal from '../modal';
import Trial from '../trial';
import TrialDetails from '../trialDetails';
import TrialGroup from '../trialGroup';
import { useHistory, useParams } from 'react-router-dom';
import { parseWithLinks } from '../../modules/htmlParser';

type Props = {
  trials: TrialData[];
  cancerTypes: CancerType[];
};

export default function Trials(props: Props) {
  const cancerTypesToShow = props.cancerTypes?.filter(t => t.hidden !== '1');

  const params = useParams<{ id?: string }>();
  const currentTrial = props.trials.find((t) => t.id === params.id);
  const currentGroup = props.cancerTypes.find((ct) => ct.slug === params.id);

  const history = useHistory();

  return (
    <div className="Trials">
      <div className="overlay" />
      <div className="page-content">
        <Header back="/menu">Enrolling Oncology Trials</Header>
        <div className="List">
          <div className="Columns">
            {cancerTypesToShow?.map((type) => (
              <TrialGroup className="Trials-group" key={type.id} data={type}>
                <>
                  {props.trials
                    .filter((t) => t.cancerTypes.includes(type.id))
                    .map((t) => (
                      <Trial className="Trials-trial" key={t.id} data={t} />
                    ))}
                  {type.trialsText && parseWithLinks(type.trialsText)}
                </>
              </TrialGroup>
            ))}
          </div>
        </div>
      </div>
      {currentGroup && (
        <Modal
          className="Trials-group-modal"
          isOpen
          header="Enrolling Oncology Trials"
          withLogo
          closeInWindow
          onClose={() => history.push('/trials')}
        >
          <>
            <TrialGroup
              className="Trials-group"
              key={currentGroup.id}
              data={currentGroup}
            >
              {props.trials
                .filter((t) => t.cancerTypes.includes(currentGroup.id))
                .map((t) => (
                  <Trial className="Trials-trial" key={t.id} data={t} />
                ))}
            </TrialGroup>
            {currentGroup.trialsText && parseWithLinks(currentGroup.trialsText)}
          </>
        </Modal>
      )}
      {currentTrial && <TrialDetails data={currentTrial} />}
    </div>
  );
}
