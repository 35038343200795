
import axios from 'axios';

export default function forceDownload(url: string): void {
	axios.get(url, { responseType: 'blob' }).then((response) => {
    const objectUrl = URL.createObjectURL(new Blob([response.data]))
    const objectName = url.split('/').pop();
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', objectName!);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
}