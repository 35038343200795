import './nav.scss';
import { NavLink } from "react-router-dom";

export default function ProgramNav() {
  return (
    <div className="ProgramNav">
      <NavLink className="ProgramNavLink" to="/program/cancer">
        <span>SEARCH</span>
        CANCER TYPE/<wbr />OTHER CATEGORIES
      </NavLink>
      <NavLink className="ProgramNavLink" to="/program/target">
        <span>SEARCH</span>
        MOLECULAR TARGET
      </NavLink>
      <NavLink className="ProgramNavLink" to="/program/phase">
        <span>SEARCH</span>
        PHASE
      </NavLink>
    </div>
  )
}