import GridBox, {GridBoxProps} from './grid-box';
import {GridGroup as GridGroupData} from '../../modules/api';
import { parseWithoutParagraphs } from '../../modules/htmlParser';

type Props = GridBoxProps & GridGroupData;

export default function GridGroup(props: Props) {
  return <GridBox {...props} id={`grid-group-${props.slug}`} className="GridGroup">
      <div className="title">
        {props.image && !props.imageGridAreaL && <img alt="" src={props.image}/>}
        {props.image && <div 
          className="thumbnail" 
          style={{backgroundImage: `url(${props.image})`}} 
        />}
        {props.programTitle && <h3>{parseWithoutParagraphs(props.programTitle)}</h3>}
      </div>
  </GridBox>;
}