/**
 * @file Provides a PDF document viewer.
 */

import './style.scss';

import parse from 'html-react-parser';
import { Trial as TrialData } from '../../modules/api';
import DocViewer from '../docViewer';
import { recordEvent } from '../../modules/stats';
import { goBack } from '../../modules/navigation'
import { useContext } from 'react';
import VersionContext from '../../contexts/versionContext';
import SessionContext from '../../contexts/sessionContext';

type Props = {
  data: TrialData;
}

export default function TrialDetails(props: Props) {
  const version = useContext(VersionContext);
  const session = useContext(SessionContext);

  const extra = (props.data.link && 
    <a 
      target="_blank" 
      rel="noreferrer" 
      href={props.data.link} 
      className="button button-primary-transparent with-arrow"
      onClick={() => recordEvent('External links', props.data.link, props.data.title)}
    >
      {parse(props.data.linkTitle)}
    </a>);

  return (
    <div className="TrialDetails">
      {props.data.document && 
        <DocViewer 
          url={props.data.document} 
          title={props.data.title} 
          onClose={()=>goBack()}
          contentType="Clinical trial"
          disclaimer={(session.isHcp === true) ? version?.compoundDisclaimer : ''}
          extra={extra}
        />
      }
    </div>
  );
}

