import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import GTag from 'react-ga4';
import { getConfig } from './modules/config';
import App from './components/app';

// Google Analytics
const GTagId = getConfig('REACT_APP_GTAG_ID');
GTagId && GTag.initialize(GTagId);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
