import { useHistory } from 'react-router-dom';
import { goBack } from '../../modules/navigation';
import './style.scss';

type Props = {
  children: string | JSX.Element | JSX.Element[],
  back?: string
}

export default function Header(props: Props) {
  const history = useHistory();
  return <div className="Header">
    <button className="back" onClick={()=> props.back ? history.push(props.back) : goBack()}>
      <span className='caption'>Back</span>
    </button>
    <h1>{props.children}</h1>
  </div>
}