import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getConfig } from './config';

export async function callApi(url: string): Promise<AxiosResponse<any>> {
  return axios.create({
    baseURL: getConfig('REACT_APP_API_URL')!.replace(/\/$/, ''),
  }).get(url);
}

export async function loadGeos(onLoad: (geos: Geo[]) => void) {
  return callApi(`geo`)
    .then((response) => { onLoad(response.data) });
}

export async function loadSearchResults(versionSlug: string, phrase: string, onLoad: (results: SearchResult[]) => void) {
  return callApi(`version/${versionSlug}/search/${phrase}`)
    .then((response) => { onLoad(response.data) });
}

export async function loadVersion(versionSlug: string, onLoad: (version: Version) => void) {
  return callApi(`version/${versionSlug}`)
    .then((response) => { onLoad(response.data[0]) });
}

export async function loadEntities<EntityType>(entityType: string, versionSlug: string, onLoad: (entities: EntityType) => void) {
  return callApi(`version/${versionSlug}/${entityType.toLowerCase()}`)
    .then((response) => { onLoad(response.data) });
}

/**
 * API Hook that loads entities by version.
 * @param entityTypePluralName 
 * @param versionName 
 * @returns [] | undefined
 */
export function useApiResource<EntityType>(entityTypePluralName: string, versionName: string) {
  const [entities, setEntities] = useState<EntityType[]>();
  useEffect(
    () => { loadEntities(entityTypePluralName, versionName, setEntities) }, 
    [entityTypePluralName, versionName]
  );
  return entities;
}

/**
 * API hook that loads and prepares all API data.
 * @param versionName 
 */
export function useApiData(versionName: string) {
  const cancerTypes = useApiResource<CancerType>('cancer-types', versionName);
  const molecularTargets = useApiResource<MolecularTarget>('molecular-targets', versionName);
  const phases = useApiResource<Phase>('phases', versionName);
  const compounds = useApiResource<Compound>('compounds', versionName);
  
  // Load compound grid boxes and link their referenced entities.
  const [gridBoxes, setgridBoxes] = useState<GridBox[]>();
  useEffect(
    () => {
      compounds !== undefined && 
      cancerTypes !== undefined && 
      molecularTargets !== undefined &&
      phases !== undefined && 
      loadEntities(
        'grid-boxes', 
        versionName,
        (entities: GridBox[]) => {
          entities.forEach((gb) => {
            gb.cancerType = cancerTypes.find(ct => gb.cancerTypeId === ct.id); 
            gb.compoundIds?.length && 
              (gb.compounds = compounds.filter(c => gb.compoundIds.includes(c.id))); 
            gb.molecularTargetIds?.length &&
              (gb.molecularTargets = molecularTargets.filter(mt => gb.molecularTargetIds.includes(mt.id)));
            gb.phaseIds?.length &&
              (gb.phases = phases.filter(p => gb.phaseIds.includes(p.id))); 
          });
          setgridBoxes(entities);
        });
    }, 
    [versionName, compounds, cancerTypes, molecularTargets, phases]
  );

  const documents = useApiResource<Document>('documents', versionName);
  const resourceCategories = useApiResource<ResourceCategory>('resource-categories', versionName);
  const staticContent = useApiResource<StaticContent>('static-content', versionName);
  const tiles = useApiResource<Tile>('tiles', versionName);
  const trials = useApiResource<Trial>('trials', versionName);
  const videos = useApiResource<Video>('videos', versionName);

  return {
    cancerTypes,
    compounds,
    gridBoxes,
    documents,
    molecularTargets,
    phases,
    resourceCategories,
    staticContent,
    tiles,
    trials,
    videos
  }
}

export interface GridGroup {
  id: string,
  slug: string,
  color: string,
  title: string,
  programTitle?: string,
  gridAreaL: string,
  gridAreaM: string,
  image: string,
  imageGridAreaL?: string,
  imageGridAreaM?: string
}

export type Document = {
  id: string,
  title: string,
  document: string,
  path: string,
  categoryId: string
}

export type Geo = {
  id: string,
  name: string
}

export type CancerType = {
  id: string,
  slug: string,
  title: string,
  color: string,
  image: string,
  programTitle: string,
  gridAreaL: string,
  gridAreaM: string,
  hidden: string, // "1" or "0"
  trialsText: string
}

export type Compound = {
  id: string,
  title: string,
  description: string,
  document: string,
}

export type GridBox = {
  id: string,
  matrix: string,
  compoundIds: string[],
  compounds: Compound[],
  title: string,
  color: string,
  description: string,
  cancerTypeId?: string,
  cancerType?: CancerType,
  molecularTargetIds: string[],
  molecularTargets: MolecularTarget[],
  phaseIds: string[],
  phases: Phase[],
  gridAreaL: string,
  gridAreaM: string,
}

export type MolecularTarget = {
  id: string,
  slug: string,
  title: string,
  programTitle: string,
  color: string,
  image: string,
  gridAreaL: string,
  gridAreaM: string,
  imageGridAreaL: string,
  imageGridAreaM: string
}

export type Phase = {
  id: string,
  slug: string,
  title: string,
  programTitle: string,
  color: string,
  image: string,
  gridAreaL: string,
  gridAreaM: string
}

export type SearchResult = {
  id: string,
  title: string,
  type: string,
  document: string,
  text: string,
  videoUrl: string,
  videoDisclaimer: string
}

export type Tile = {
  id: string,
  color: string,
  cssClass: string,
  body: string
}

export type Trial = {
  id: string,
  title: string,
  description: string,
  cancerTypes: string[],
  document: string,
  link: string,
  linkTitle: string
}

export type Version = {
  title: string,
  slug: string,
  homeText: string,
  copyrights: string,
  announcement: string,
  programTitle: string,
  programFooter: string,
  css?: string,
  leavingWarning: string,
  leavingWarningWhitelist: string,
  rules: string,
  authMethod: AuthMethod,
  password: string,
  hcpPassword: string,
  compoundDisclaimer: string
}

export type Video = {
  id: string,
  title: string,
  path: string,
  url: string,
  categoryId: string,
  disclaimer: string
}

export type StaticContent = {
  id: string,
  title: string,
  text: string,
  path: string
}

export type ResourceCategory = {
  id: string,
  title: string,
  color: string,
  image: string
}

export enum AuthMethod {
  geo = 'hcp_geo',
  password = 'password'
}
