import './style.scss';

import cn from 'classnames';
import parse from 'html-react-parser';
import { SearchResult as SearchResultData } from '../../modules/api';

type Props = {
  className: string,
  data: SearchResultData,
  onClick: () => void
}

const excludedTypeCaptions = ['Static content'];

export default function SearchResult(props: Props) {
  return (
    <div className={cn('SearchResult', props.className)} onClick={()=>props.onClick()}>
      {parse(props.data.title)} 
      {!excludedTypeCaptions.includes(props.data.type) && <span className="type">{props.data.type}</span>}
    </div>
  );
}