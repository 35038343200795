import { FormEventHandler, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import md5 from 'md5'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/images/logo.svg'
import '../../auth/style.scss'
import { getConfig } from '../../../modules/config'
import { useContext } from 'react';
import VersionContext from '../../../contexts/versionContext';

type Props = {
  onSubmit: (isAuth: boolean) => void
}

export const AuthPassword = (props: Props) => {
  const version = useContext(VersionContext);

  const pwInput = useRef<HTMLInputElement>(null)
  const cookiesExpiresDate: Date = new Date(new Date().setMonth(new Date().getMonth() + 2))
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['pod_pw'])
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  
  const handleOnChangeCheckbox = () => setIsChecked(!isChecked)

  const onSubmit:FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    if (version?.password === password || version?.hcpPassword === password) {
      setCookie('pod_pw', md5(password), {path: '/', expires: cookiesExpiresDate})
      props.onSubmit(true)
    } else {
      setIsValid(false)
      setTimeout(() => {
        setPassword('')
        pwInput.current?.focus()
        setIsValid(true)
      }, 700)
    }
  }

  const domainName = getConfig('REACT_APP_DOMAIN_NAME', window.location.hostname);
  
  return (
    <div className={isValid ? 'Auth-window Auth-window--password' : 'Auth-window Auth-window--password shake'}>
      <div className="Branding">
        <Link className="Logo" to="/"><img src={Logo} alt="Pfizer Oncology" /></Link>
      </div>
      <form className="Auth-form" onSubmit={onSubmit}>
        <h2>Please enter password to&nbsp;continue&nbsp;to {domainName}</h2>
        <div className="wrapper">
          <input type="checkbox" id="password-confirm" onChange={handleOnChangeCheckbox}/>
          <label htmlFor="password-confirm">To continue, please tick to confirm you have read the terms within the email sent to you by your Pfizer colleague</label>
        </div>
        <div className="warpper-password">
          <label htmlFor="password">Password</label>
          <input type="password" id="password" value={password} onChange={(event)=>setPassword(event.target.value)} ref={pwInput} />
        </div>
        <input className="button button-primary" type="submit" value="Login" disabled={!(isChecked && password.length > 0)} />
      </form>
    </div>
  )
}