import './style.scss';
import Vimeo from '@u-wave/react-vimeo';
import Player from 'vimeo__player';
import cn from 'classnames';
import { useRef, useState, useEffect } from 'react';
import { parseWithLinks } from '../../modules/htmlParser';
import { recordEvent } from '../../modules/stats';

type Props = {
  className?: string,
  title: string,
  url: string,
  disclaimer: string,
  onClose: () => void
}

export default function Video(props: Props) {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(props.disclaimer.trim() === '');

  const playerRef = useRef<Player>()
  const onPlayerReady = (player: Player) => {
    playerRef.current = player
  }
  disclaimerAccepted && playerRef?.current?.play();

  useEffect(() => {
    recordEvent('Content views', props.title, 'Video');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={cn("Video", props.className)}>
    <div className="overlay">
      <button className="close" onClick={()=>props.onClose()}/>
      <div className="window">
        {!disclaimerAccepted && <div className="disclaimer">
          <div>
            {parseWithLinks(props.disclaimer)}
            <button className="button button-primary with-arrow" onClick={()=>setDisclaimerAccepted(true)}>Continue</button>
          </div>
        </div>}
        <Vimeo  
          className="player" 
          width="100%" 
          height="100%"
          video={props.url} 
          onReady={onPlayerReady} 
          color="0095fe"
          showTitle={false}
          autoplay={disclaimerAccepted}
        />
      </div>
    </div>
  </div>
}