import './style.scss'

import cn from 'classnames';

type Props = {
  className: string,
  children: string | JSX.Element | JSX.Element[],
  color?: string,
}

export default function Tile(props: Props) {
  const styles = {
    backgroundColor: props.color
  }

  return (
    <div className={cn('Tile', props.className)}>
      <div className="tile-content-wrap" style={styles}>
        <div className="tile-content">
          {props.children}
        </div>
      </div>
    </div>
  );
}