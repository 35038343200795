import GridBox, { GridBoxProps } from './grid-box';
import { GridBox as GridBoxData} from '../../modules/api';

import cn from 'classnames';
import { parseWithLinks } from '../../modules/htmlParser';


type Props = GridBoxProps & {
  data: GridBoxData
}

export default function CompoundGridBox(props: Props) {
  const classNames = cn([
    'CompoundGridBox', 
    {'has-compounds': props.data.compounds },
    {'has-multiple-compounds': props.data.compoundIds && props.data.compoundIds.length > 1}
  ]);

  return <GridBox className={classNames} {...props}>
    {props.data.compounds && <ul className="compounds">
      {props.data.compounds?.map(c => 
        <li className="compound" key={c.id}>
          <div className="title">{c.title}</div>
          <div className="description">({c.description})</div>
        </li>
      )}
    </ul>}
    <div className="description">
      {parseWithLinks(props.data.description)}
    </div>
  </GridBox>;
}