import md5 from "md5"

function isValidPassword(cookiesPassword: string, password: string): boolean {
  return cookiesPassword === md5(password);
}

export function isHcp(cookies: any, hcpPassword: string): boolean {
  return cookies?.pod_pw === md5(hcpPassword);
}

export const isAuthenticated = (cookies: any, method?: string, password?: string, hcpPassword?: string): boolean => {
  if (method) {
    switch (method) {
      case "hcp_geo":
        return Boolean(cookies.pod_geo)
      case "password":
        return (password !== undefined && isValidPassword(cookies.pod_pw, password)) || 
          (hcpPassword !== undefined && isValidPassword(cookies.pod_pw, hcpPassword));
      default:
        return false
    }
  }
  return false
}